import React from 'react';
import Typography from '@mui/material/Typography';
import { LayoutType } from '../../types/layout-type.enum';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { QuestionLinkWidget } from './question-link-widget.component';

export const WidgetWatermarkComponent = ({ layout, onUpgrade }: { layout: LayoutType; onUpgrade: () => void }) => (
    <>
        <Tooltip
            title={
                <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                    Remove watermark
                </Typography>
            }
        >
            <QuestionLinkWidget onClick={onUpgrade} style={{ marginBottom: layout === LayoutType.carousel ? 0 : '64px' }} />
        </Tooltip>
    </>
);
