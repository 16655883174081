import styled from 'styled-components';
import GLogo from '../../assets/google.svg';
import YLogo from '../../assets/yelp.svg';
import TLogo from '../../assets/trustpilot.svg';
import FLogo from '../../assets/facebook.svg';

export const SummaryRoot = styled.div`
    width: 190px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    background: ${({ theme }) => theme.colors.cardBackground};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};

    @media screen and (max-width: 900px) {
        padding: 16px 8px;
        width: 150px;
    }
`;

export const MultipleRoot = styled.div<{ $marginSmall?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ $marginSmall }) => ($marginSmall ? '24px' : '40px')};
    gap: 8px;
    justify-content: center;
`;

export const TotalText = styled.div`
    color: ${({ theme }) => theme.colors.inactiveGrey};
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: -0.28px;

    @media screen and (max-width: 900px) {
        font-size: 12px !important;
    }
`;

const iconStyle = `width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.25s transform ease-in-out;
    &:hover {
        transform: scale(1.2);
    }
    @media screen and (max-width: 900px) {
        width: 20px;
        height: 20px;
    }
    `;
export const GoogleSummaryLogo = styled.img.attrs({ src: GLogo })`
    ${iconStyle}
`;

export const YelpSummaryLogo = styled.img.attrs({ src: YLogo })`
    ${iconStyle}
`;

export const TrustpilotSummaryLogo = styled.img.attrs({ src: TLogo })`
    ${iconStyle}
`;
export const FacebookSummaryLogo = styled.img.attrs({ src: FLogo })`
    ${iconStyle}
`;

export const NumbersWrapper = styled.div`
    display: flex;
    padding: 2px 12px 2px 2px;
    align-items: center;
    gap: 8px;
    border-radius: 43px;
    background: ${({ theme }) => theme.colors.light};
`;

export const RatingWrapper = styled.div`
    display: flex;
    padding: 4px 10px 4px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 999px;
    background: ${({ theme }) => theme.colors.cardBackground};
    cursor: pointer;

    svg {
        transition: transform 0.25s ease-in-out;
    }

    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
`;

export const RatingText = styled.span`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 900px) {
        font-size: 14px;
    }
`;
