import styled from 'styled-components';

export const SettingsContainer = styled.div`
    width: 338px;
    height: calc(100% - 40px);
    overflow: scroll;
    padding: 0 16px 0 2px;
`;

export const WidgetContainer = styled.div<{ $center?: boolean }>`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
    min-width: 500px;
    overflow: scroll;
    padding: 32px 0;
    background: ${({ theme }) => theme.colors.bg};
`;

export const FreePlanLimitationText = styled.div`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textSecondary};
    text-align: center;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 500;
`;
