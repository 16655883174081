import React from 'react';
import { LoadingIndicator, Root, TextWrapper } from './space-button.styles';
import { StarsBackground } from '../stars-background/stars-background.component';

interface Props {
    onClick: () => Promise<void> | void;
    loading?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    hidden?: boolean;
    id?: string;
    variant?: 'primary' | 'secondary';
}
export const SpaceButton = ({ onClick, loading, children, disabled, id, variant }: Props) => {
    return (
        <Root $disabled={disabled} onClick={onClick} id={id ?? ''} $variant={variant}>
            <StarsBackground />
            <TextWrapper $loading={loading}>{children}</TextWrapper>
            {loading && <LoadingIndicator size={30} color={'inherit'} />}
        </Root>
    );
};
