import React, { useMemo, useState } from 'react';
import { ProfilePageWrapper, ProfileRoot, ProfileSideLayout } from './profile-page.styles';
import { UserPopulatedType } from '../../types/user-data.type';
import { SettingsType } from '../../types/settings.type';
import { ReviewsPreviewType } from '../../types/review-data.type';
import { SideBarComponent } from '../../components/side-bar/side-bar.component';
import { ReviewsSection } from '../../sections/reviews-section/reviews-section.component';
import { pinSortNewest } from '../../utils/pinSort';
import { ReviewFormBuilder } from '../../sections/collect-section/review-form-builder.component';
import { WidgetSection } from '../../sections/widget-section/widget-section.component';
import { RequestSection } from '../../sections/requests-section/request-section.component';
import { VideoSection } from '../../sections/video-section/video-section.component';
import { AccountSection } from '../../sections/account-section/account-section.component';
import { SubscriptionActivateModal } from '../../sections/account-section/subscription-activate.modal';
import { DesktopWarningModal } from '../../sections/account-section/desktop-warning.modal';

interface Props {
    userPopulatedData?: UserPopulatedType;
    onUserPopulatedDataChange: (data: UserPopulatedType) => void;

    settings: SettingsType;
    onSettingsChange: (settings: SettingsType) => void;
    onLogout: () => void;

    onTriggerConfetti: () => void;
}

export const ProfilePage = ({
    userPopulatedData,
    onSettingsChange,
    settings,
    onLogout,
    onTriggerConfetti,
    onUserPopulatedDataChange,
}: Props) => {
    const [platformPreviewData, setPlatformPreviewData] = useState<ReviewsPreviewType>();
    const [activeSection, setActiveSection] = useState(() => {
        const path = window.location.pathname.split('/').pop();
        return path || 'widget';
    });

    const onPlatformPreviewDataChanged = (data: ReviewsPreviewType) => {
        if (data) {
            onTriggerConfetti();
        }
        setPlatformPreviewData(data);
    };

    const onPlanCancelled = () => {
        userPopulatedData.user.plan = 'free';
        onUserPopulatedDataChange({ ...userPopulatedData });
    };

    const items = useMemo(
        () =>
            [...(userPopulatedData.reviews || []), ...(platformPreviewData?.reviews || [])]
                ?.filter(
                    (el) =>
                        (el.forceState === 1 || (el.rating >= settings.minRating && el.forceState !== -1)) &&
                        (el.text || el.videoUrl)
                )
                .sort(pinSortNewest)
                .slice(0, 100),
        [JSON.stringify(userPopulatedData.reviews), platformPreviewData?.reviews, JSON.stringify(settings)]
    );

    const summaryInfo = {
        googleRatingsTotal: userPopulatedData.user.googleRatingsTotal || platformPreviewData?.ratingsTotal,
        googleRating: userPopulatedData.user.googleRating || platformPreviewData?.rating,
        googleCompanyId: userPopulatedData.user.googleCompanyId || platformPreviewData?.googleCompanyId,
        yelpRating: userPopulatedData.user.yelpRating || platformPreviewData?.rating,
        yelpRatingsTotal: userPopulatedData.user.yelpRatingsTotal || platformPreviewData?.ratingsTotal,
        yelpAlias: userPopulatedData.user.yelpAlias || platformPreviewData?.yelpAlias,
        facebookRating: userPopulatedData.user.facebookRating || platformPreviewData?.rating,
        facebookRatingsTotal: userPopulatedData.user.facebookRatingsTotal || platformPreviewData?.ratingsTotal,
        facebookAlias: userPopulatedData.user.facebookAlias || platformPreviewData?.facebookAlias,
        trustpilotRating: userPopulatedData.user.trustpilotRating || platformPreviewData?.rating,
        trustpilotRatingsTotal: userPopulatedData.user.trustpilotRatingsTotal || platformPreviewData?.ratingsTotal,
        trustpilotAlias: userPopulatedData.user.trustpilotAlias || platformPreviewData?.trustpilotAlias,
    };

    const onUpgradeClicked = () => {
        setActiveSection('account');
    };

    const isProPlan = userPopulatedData.user.plan === 'pro';

    return (
        <ProfileRoot>
            <ProfilePageWrapper>
                <ProfileSideLayout>
                    <SideBarComponent value={activeSection} onChange={setActiveSection} />
                    {activeSection === 'widget' && (
                        <WidgetSection
                            settings={settings}
                            onSettingsChange={onSettingsChange}
                            platformPreviewData={platformPreviewData}
                            onPreviewDataChange={onPlatformPreviewDataChanged}
                            items={items}
                            summaryInfo={summaryInfo}
                            userPopulatedData={userPopulatedData}
                            onUserPopulatedDataChange={onUserPopulatedDataChange}
                            isProPlan={isProPlan}
                            onUpgrade={onUpgradeClicked}
                        />
                    )}
                    {activeSection === 'reviews' && (
                        <ReviewsSection
                            summaryInfo={summaryInfo}
                            settings={settings}
                            userPopulatedData={userPopulatedData}
                            onUserPopulatedDataChange={onUserPopulatedDataChange}
                        />
                    )}
                    {activeSection === 'builder' && (
                        <ReviewFormBuilder
                            userPopulatedData={userPopulatedData}
                            onUpgrade={() => setActiveSection('account')}
                            isProPlan={isProPlan}
                        />
                    )}
                    {activeSection === 'requests' && (
                        <RequestSection
                            companyName={userPopulatedData.user.companyName}
                            logoUrl={userPopulatedData.user.logo}
                            isProPlan={isProPlan}
                            onUpgrade={onUpgradeClicked}
                        />
                    )}
                    {activeSection === 'video' && <VideoSection minRating={userPopulatedData.user.settings.minRating} />}
                    {activeSection === 'account' && (
                        <AccountSection
                            plan={userPopulatedData.user.plan}
                            stripeCustomerId={userPopulatedData.user.stripeCustomerId}
                            cancellationDate={userPopulatedData.user.cancellationDate}
                            subscriptionInterval={userPopulatedData.user.subscriptionInterval}
                            onCancelled={onPlanCancelled}
                        />
                    )}
                </ProfileSideLayout>
            </ProfilePageWrapper>
            {/*<LogoutButton onClick={onLogout}/>*/}
            {window.innerWidth <= 768 && <DesktopWarningModal />}
            <SubscriptionActivateModal />
        </ProfileRoot>
    );
};
