import React, { useMemo } from 'react';
import {
    FacebookSummaryLogo,
    GoogleSummaryLogo,
    MultipleRoot,
    NumbersWrapper,
    RatingText,
    RatingWrapper,
    SummaryRoot,
    TotalText,
    TrustpilotSummaryLogo,
    YelpSummaryLogo,
} from './reviews-summary.styles';
import { ReviewSource } from '../../types/review-source.enum';

const StarIcon = () => (
    <svg
        width="24"
        height="24"
        style={{ width: 'unset', height: 'unset' }}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z"
            fill="#ffca05"
        />
    </svg>
);

interface PropsPreview {
    rating: number;
    ratingsTotal: number;
    googleCompanyId?: string;
    yelpAlias?: string;
    trustpilotAlias?: string;
    facebookAlias?: string;
}

export const ReviewsSummaryPreview = (props: PropsPreview) => {
    if (props.yelpAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.yelp} {...props} />
            </MultipleRoot>
        );
    }
    if (props.trustpilotAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.trustpilot} {...props} />
            </MultipleRoot>
        );
    }
    if (props.facebookAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.facebook} {...props} />
            </MultipleRoot>
        );
    }
    if (props.googleCompanyId) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.google} {...props} />
            </MultipleRoot>
        );
    }
};

export interface SummaryStats {
    googleRating: number;
    googleRatingsTotal: number;
    googleCompanyId?: string;

    yelpRating?: number;
    yelpRatingsTotal?: number;
    yelpAlias?: string;

    facebookRating?: number;
    facebookRatingsTotal?: number;
    facebookAlias?: string;

    trustpilotRating?: number;
    trustpilotRatingsTotal?: number;
    trustpilotAlias?: string;

    marginSmall?: boolean;
}

export const ReviewsSummaryUser = (props: SummaryStats) => {
    return <MultiplePlatformsSummary {...props} />;
};

const ReviewsSummaryPlatformWidget = ({
    ratingsTotal,
    rating,
    googleCompanyId,
    yelpAlias,
    source,
    trustpilotAlias,
    facebookAlias,
}: PropsPreview & {
    source: ReviewSource;
}) => {
    const openGoogleUrl = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${googleCompanyId}`);
    };
    const openYelpUrl = () => {
        window.open(`https://www.yelp.com/biz/${yelpAlias}`);
    };

    const openTrustpilotUrl = () => {
        window.open(`https://www.trustpilot.com/review/${trustpilotAlias}`);
    };

    const openFacebookUrl = () => {
        window.open(`https://www.facebook.com/${facebookAlias}`);
    };

    const metadata = useMemo(() => {
        if (source === ReviewSource.yelp) {
            return { callback: openYelpUrl, logo: <YelpSummaryLogo onClick={openYelpUrl} /> };
        } else if (source === ReviewSource.google) {
            return { callback: openGoogleUrl, logo: <GoogleSummaryLogo onClick={openGoogleUrl} /> };
        } else if (source === ReviewSource.trustpilot) {
            return { callback: openTrustpilotUrl, logo: <TrustpilotSummaryLogo onClick={openTrustpilotUrl} /> };
        } else if (source === ReviewSource.facebook) {
            return { callback: openFacebookUrl, logo: <FacebookSummaryLogo onClick={openFacebookUrl} /> };
        }
    }, [source]);

    return (
        <SummaryRoot>
            {metadata.logo}
            <NumbersWrapper>
                <RatingWrapper onClick={metadata.callback}>
                    <StarIcon /> <RatingText>{rating}</RatingText>
                </RatingWrapper>
                <TotalText>{ratingsTotal}</TotalText>
            </NumbersWrapper>
        </SummaryRoot>
    );
};

const MultiplePlatformsSummary = (props: SummaryStats) => {
    return (
        <MultipleRoot $marginSmall={props.marginSmall}>
            {props.googleCompanyId && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.googleRating}
                        ratingsTotal={props.googleRatingsTotal}
                        source={ReviewSource.google}
                        googleCompanyId={props.googleCompanyId}
                    />
                </>
            )}
            {props.yelpAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.yelpRating}
                        ratingsTotal={props.yelpRatingsTotal}
                        source={ReviewSource.yelp}
                        yelpAlias={props.yelpAlias}
                    />
                </>
            )}
            {props.facebookAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.facebookRating}
                        ratingsTotal={props.facebookRatingsTotal}
                        source={ReviewSource.facebook}
                        facebookAlias={props.facebookAlias}
                    />
                </>
            )}
            {props.trustpilotAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.trustpilotRating}
                        ratingsTotal={props.trustpilotRatingsTotal}
                        source={ReviewSource.trustpilot}
                        trustpilotAlias={props.trustpilotAlias}
                    />
                </>
            )}
        </MultipleRoot>
    );
};

export default ReviewsSummaryUser;
