import React, { useState } from 'react';
import { SectionContentWrapper, SectionDashRoot, SectionTitle } from '../../components/common.styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Stack from '@mui/material/Stack';
import { EditableTitle } from '../../components/editable-title/editable-title.component';
import { LayoutType } from '../../types/layout-type.enum';
import { ReviewsCarouselComponent } from '../../components/reviews-carousel/reviews-carousel.component';
import { ReviewsMasonryGridComponent } from '../../components/reviews-masonry-grid/reviews-masonry-grid.component';
import { ReviewsWallComponent } from '../../components/reviews-wall/reviews-wall.component';
import { ReviewsSummaryUser } from '../../components/reviews-summary/reviews-summary.component';
import { SettingsType } from '../../types/settings.type';
import { UserPopulatedType } from '../../types/user-data.type';
import { ReviewType } from '../../types/review.type';
import { IntegrationGuideModal } from '../../components/integration-guide-modal/integration-guide.modal';
import { FreePlanLimitationText, SettingsContainer, WidgetContainer } from './widget-section.styles';
import { ProfileWidgetSettings } from '../../components/profile-widget-settings/profile-widget-settings.component';
import { ReviewsPreviewType } from '../../types/review-data.type';
import { themeMui, themeMuiDark, themeStyledComponents, themeStyledComponentsDark } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { VideoLayout } from '../../components/video-layout/video-layout.component';
import { LockIcon } from '../../components/lock-icon.component';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { WidgetWatermarkComponent } from './widget-watermark.component';

interface Props {
    settings: SettingsType;
    onSettingsChange: (settings: SettingsType) => void;
    userPopulatedData?: UserPopulatedType;
    platformPreviewData: ReviewsPreviewType;
    onPreviewDataChange: (previewData?: ReviewsPreviewType) => void;
    items: ReviewType[];
    summaryInfo: any;
    onUserPopulatedDataChange: (data: UserPopulatedType) => void;
    isProPlan?: boolean;
    onUpgrade: () => void;
}
export const WidgetSection = ({
    settings,
    onSettingsChange,
    userPopulatedData,
    platformPreviewData,
    onPreviewDataChange,
    items,
    summaryInfo,
    onUserPopulatedDataChange,
    isProPlan,
    onUpgrade,
}: Props) => {
    const [isIntegrationDialogOpen, setIsIntegrationDialogOpen] = useState(false);
    return (
        <>
            <IntegrationGuideModal
                open={isIntegrationDialogOpen}
                onClose={() => {
                    setIsIntegrationDialogOpen(false);
                }}
                userId={userPopulatedData?.user?._id}
            />
            <SectionDashRoot>
                <SectionContentWrapper $fullHeight>
                    <Stack direction="row" gap={1} height="100%" flexWrap="wrap">
                        <Stack gap={3} height="100%">
                            <SectionTitle>Widget Settings</SectionTitle>
                            <SettingsContainer>
                                <ProfileWidgetSettings
                                    userPopulatedData={userPopulatedData}
                                    onPreviewDataChange={onPreviewDataChange}
                                    onUserPopulatedDataChange={onUserPopulatedDataChange}
                                    platformPreviewData={platformPreviewData}
                                    settings={settings}
                                    onSettingsChange={onSettingsChange}
                                    onIntegrationOpen={() => setIsIntegrationDialogOpen(true)}
                                    isProPlan={isProPlan}
                                    onUpgrade={onUpgrade}
                                />
                            </SettingsContainer>
                        </Stack>
                        <StyledThemeProvider theme={settings.isDark ? themeStyledComponentsDark : themeStyledComponents}>
                            <ThemeProvider theme={settings.isDark ? themeMuiDark : themeMui}>
                                <WidgetContainer
                                    $center={
                                        settings.layout === LayoutType.carousel ||
                                        settings.layout === LayoutType.list ||
                                        !settings.layout
                                    }
                                    className={settings.isDark && 'wrapper-dark'}
                                >
                                    {!isProPlan && (
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <Typography
                                                    color="white"
                                                    typography="subtitle1"
                                                    sx={{ fontSize: '12px' }}
                                                    textAlign="center"
                                                >
                                                    Upgrade to display an unlimited number of reviews from multiple platforms
                                                </Typography>
                                            }
                                        >
                                            <FreePlanLimitationText>
                                                <LockIcon size={24} />
                                                <span>
                                                    Only the <strong>most recent 6 reviews</strong> are visible in the website
                                                    widget with the Free Plan.
                                                </span>
                                                <LockIcon size={24} />
                                            </FreePlanLimitationText>
                                        </Tooltip>
                                    )}
                                    {settings.showTitle && <EditableTitle clickable value={settings.title} />}
                                    {(settings.layout === LayoutType.carousel || !settings.layout) && (
                                        <ReviewsCarouselComponent
                                            items={items}
                                            autoplay={settings.autoplay}
                                            accentColor={settings.accentColor}
                                            aiHighlight={settings.aiHighlight}
                                            isProPlan={isProPlan}
                                        />
                                    )}
                                    {settings.layout === LayoutType.masonry && (
                                        <ReviewsMasonryGridComponent
                                            items={items}
                                            accentColor={settings.accentColor}
                                            aiHighlight={settings.aiHighlight}
                                            isProPlan={isProPlan}
                                        />
                                    )}
                                    {settings.layout === LayoutType.list && (
                                        <ReviewsWallComponent
                                            items={items}
                                            accentColor={settings.accentColor}
                                            aiHighlight={settings.aiHighlight}
                                            isProPlan={isProPlan}
                                        />
                                    )}
                                    {settings.layout === LayoutType.video && (
                                        <VideoLayout
                                            items={items}
                                            accentColor={settings.accentColor}
                                            aiHighlight={settings.aiHighlight}
                                            isProPlan={isProPlan}
                                        />
                                    )}
                                    {settings.showSummary && (
                                        <ReviewsSummaryUser marginSmall={settings.layout === LayoutType.video} {...summaryInfo} />
                                    )}
                                    <WidgetWatermarkComponent onUpgrade={onUpgrade} layout={settings.layout} />
                                </WidgetContainer>
                            </ThemeProvider>
                        </StyledThemeProvider>
                    </Stack>
                </SectionContentWrapper>
            </SectionDashRoot>
        </>
    );
};
