import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const DesktopWarningModal = () => {
    const [open, setOpen] = useState(true);
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Root style={{ width: 'unset' }}>
                <iframe
                    style={{ border: 'none' }}
                    src="https://lottie.host/embed/60542d50-3246-4970-bd6d-f538f3bb2806/8hkG4CeZGn.lottie"
                ></iframe>
                <Typography fontSize="18px" fontWeight="500" textAlign="center" whiteSpace="nowrap">
                    For the best experience,
                    <br /> Please use a desktop device.
                </Typography>
                <Button variant="outlined" onClick={onClose}>
                    Continue
                </Button>
            </Root>
        </Dialog>
    );
};

const Root = styled.div`
    border-radius: 24px;
    width: 400px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;
